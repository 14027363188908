import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import ContactUsHeader from "../components/ContactUs/ContactUsHeader"
import ContactUsForm from "../components/ContactUs/ContactUsForm"

const SecondPage = () => (
  <Layout currentPage="contactus">
    <SEO title="Contact Us" />
    <ContactUsHeader />
    <ContactUsForm />
  </Layout>
)

export default SecondPage
