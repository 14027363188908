import React, { useState } from "react"
import "./ContactUsForm.scss"
import Button from "./../../shared/Button"
import { init } from "emailjs-com"
import emailjs from "emailjs-com"

init("user_lyPs2S2dJBobWCV958mf3")

const ContactUsForm = () => {

  const [sendText, setSendText] = useState("Send");

  const handleSubmission = e => {
    setSendText("Sending...");
    emailjs
      .sendForm(
        "default_service",
        "template_8nkqkm7",
        e.target,
        "user_lyPs2S2dJBobWCV958mf3"
      )
      .then(
        result => {
          console.log("SUCCESS", result.text)
          setSendText("Received")
        },
        error => {
          console.log("FAILED", error.text)
          setSendText("Failed")
        },
      )
    e.preventDefault()
  }

  return (
    <div
      style={{
        backgroundColor: "#fafafa",
      }}
    >
      <div className="Wrapper">
        <form
          onSubmit={e => handleSubmission(e)}
          className="ContactUsForm "
          method="GET"
          encType="text/html"
        >
          <input
            required
            className="InputField"
            type="text"
            id="name"
            name="name"
            placeholder="Name"
          />
          <input
            required
            className="InputField"
            type="email"
            id="email"
            name="email"
            placeholder="Email"
          />
          <input
            required
            className="InputField"
            type="text"
            id="phone"
            name="phone"
            placeholder="Phone"
          />
          <textarea
            required
            className="InputField Textarea"
            name="message"
            id="message"
            placeholder="Message"
            cols="30"
            rows="7"
          />

          <Button
            type="submit"
            label={sendText}
            theme="primary"
            style={{
              width: "157px",
            }}
            //onclick to be implemented when agree on the functionality
          />
        </form>

        <div className="Headquarter">
          <div className="title">US Office</div>
          <div className="address HeadquarterSection">
            <div>Address:</div>
            <div>138 Working Ranch. Irvine, CA 92602</div>
          </div>
          <div className="phone HeadquarterSection">
            <div>Phone:</div>
            <div>+1(949)533-9362</div>
          </div>
          <div className="email HeadquarterSection">
            <div>Email:</div>
            <div>info@foothillsolutions.com</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUsForm
