import React from "react"
import "./ContactUsHeader.scss"
import ContactUsMap from "../../../images/contactusMap.png"

const ContactUsHeader = () => (
  <div
    className="ContactUsHeader"
    style={{ backgroundImage: `url(${ContactUsMap})` }}
  >
    <div className="title">Get In Touch With Us</div>
    <div className="ContactUsDescription">
      We are here to help. Want to learn more about our services? Please get in
      touch, we'd love to hear from you!
    </div>
  </div>
)

export default ContactUsHeader
